import { DownloadOutlined } from '@ant-design/icons';
import { DateField, EmailField, Show, TextField } from '@refinedev/antd';
import { IResourceComponentsProps, useShow } from '@refinedev/core';
import { Button, Col, Rate, Row, Space, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { isNotNil, isNotNilOrEmpty } from 'src/utilities/functions';

import { STRAPI_MEDIA_URL } from '../../constants';
import { Candidate } from '../../utilities/types';

const { Title } = Typography;

const CandidateShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<Candidate>({
    meta: {
      populate: ['resume', 'letter', 'links', 'jobPosition']
    }
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Row gutter={30}>
        <Col>
          <Title level={5}>First Name</Title>
          <TextField value={record?.firstName} />
        </Col>
        <Col>
          <Title level={5}>Last Name</Title>
          <TextField value={record?.lastName} />
        </Col>
      </Row>
      <Row gutter={30} style={{ marginTop: 20 }}>
        <Col>
          <Title level={5}>Email</Title>
          <EmailField value={record?.email} />
        </Col>
      </Row>
      {isNotNil(record) && isNotNilOrEmpty(record.links) && (
        <Row gutter={30} style={{ marginTop: 20 }}>
          <Col span={24}>
            <Title level={5}>Links</Title>
            <Space direction="vertical">
              {record.links.map((link) => (
                <Link to={link.url} target="_blank">
                  {link.url}
                </Link>
              ))}
            </Space>
          </Col>
        </Row>
      )}
      {record?.jobPosition && (
        <Row gutter={30} style={{ marginTop: 20 }}>
          <Col span={24}>
            <Title level={5}>Job Position</Title>
            <Link to={`/jobpositions/show/${record.jobPosition?.id}`}>{record.jobPosition.title}</Link>
          </Col>
        </Row>
      )}
      <Row gutter={30} style={{ marginTop: 20 }}>
        <Col span={8}>
          <Title level={5}>Notes</Title>
          <TextField value={record?.notes} />
        </Col>
        <Col>
          <Title level={5}>Interview Date</Title>
          {record?.interviewDate && <DateField value={record?.interviewDate} format="YYYY-MM-DD" />}
        </Col>
        <Col>
          <Title level={5}>Rating</Title>
          <Rate value={record?.rating} disabled allowHalf />
        </Col>
      </Row>
      {(record?.resume || record?.letter) && (
        <>
          <Title level={5} style={{ marginTop: 20 }}>
            Actions
          </Title>
          <Row gutter={30}>
            {record?.resume && (
              <Col>
                <Button target="_blank" href={`${STRAPI_MEDIA_URL}${record.resume.url}`}>
                  <DownloadOutlined /> Download Resume
                </Button>
              </Col>
            )}
            {record?.letter && (
              <Col>
                <Button target="_blank" href={`${STRAPI_MEDIA_URL}${record.letter.url}`}>
                  <DownloadOutlined /> Download Motivational letter
                </Button>
              </Col>
            )}
          </Row>
        </>
      )}
    </Show>
  );
};

export default CandidateShow;
