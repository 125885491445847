import '@refinedev/antd/dist/reset.css';

import { ReconciliationOutlined, UserOutlined } from '@ant-design/icons';
import { AuthPage, ErrorComponent, Layout, notificationProvider } from '@refinedev/antd';
import { Authenticated, Refine } from '@refinedev/core';
import { RefineKbar, RefineKbarProvider } from '@refinedev/kbar';
import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier
} from '@refinedev/react-router-v6';
import { DataProvider } from '@refinedev/strapi-v4';
import { ConfigProvider, Image, theme } from 'antd';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import { authProvider, axiosInstance } from './authProvider';
import Header from './components/header';
import Title from './components/title';
import { STRAPI_HOST } from './constants';
import CandidateEdit from './pages/candidates/edit';
import CandidateList from './pages/candidates/list';
import CandidateShow from './pages/candidates/show';
import JobPositionCreate from './pages/jobPositions/create';
import JobPositionEdit from './pages/jobPositions/edit';
import JobPositionList from './pages/jobPositions/list';
import JobPositionShow from './pages/jobPositions/show';

function App() {
  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ConfigProvider
          theme={{
            algorithm: theme.compactAlgorithm
          }}
        >
          <Refine
            authProvider={authProvider}
            dataProvider={DataProvider(`${STRAPI_HOST}/api`, axiosInstance)}
            notificationProvider={notificationProvider}
            resources={[
              {
                name: 'candidates',
                list: '/candidates',
                edit: '/candidates/edit/:id',
                show: '/candidates/show/:id',
                canDelete: false,
                meta: { label: 'Candidates', icon: <UserOutlined /> }
              },
              {
                name: 'jobpositions',
                list: '/jobpositions',
                create: '/jobpositions/create',
                edit: '/jobpositions/edit/:id',
                show: '/jobpositions/show/:id',
                meta: {
                  label: 'Job Positions',
                  icon: <ReconciliationOutlined />
                }
              }
            ]}
            routerProvider={routerBindings}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                    <Layout Title={Title} Header={Header}>
                      <Outlet />
                    </Layout>
                  </Authenticated>
                }
              >
                <Route index element={<NavigateToResource resource="candidates" />} />
                <Route path="/candidates">
                  <Route index element={<CandidateList />} />
                  <Route path="show/:id" element={<CandidateShow />} />
                  <Route path="edit/:id" element={<CandidateEdit />} />
                </Route>
                <Route path="/jobpositions">
                  <Route index element={<JobPositionList />} />
                  <Route path="create" element={<JobPositionCreate />} />
                  <Route path="edit/:id" element={<JobPositionEdit />} />
                  <Route path="show/:id" element={<JobPositionShow />} />
                </Route>
              </Route>
              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route
                  path="/login"
                  element={
                    <AuthPage
                      type="login"
                      wrapperProps={{
                        style: {
                          background: '#222'
                        }
                      }}
                      contentProps={{
                        headStyle: {
                          display: 'none'
                        }
                      }}
                      renderContent={(content: React.ReactNode) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <Image src={'/msLogoFull.svg'} style={{ padding: 20 }} preview={false} />
                            {content}
                          </div>
                        );
                      }}
                      registerLink={false}
                      forgotPasswordLink={false}
                    />
                  }
                />
              </Route>
              <Route
                element={
                  <Authenticated>
                    <Layout Title={Title} Header={Header}>
                      <Outlet />
                    </Layout>
                  </Authenticated>
                }
              >
                <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>
            <RefineKbar />
            <UnsavedChangesNotifier />
          </Refine>
        </ConfigProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
