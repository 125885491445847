import { SearchOutlined } from '@ant-design/icons';
import { ExportButton, List, useSelect, useTable } from '@refinedev/antd';
import { CrudFilters, HttpError, IResourceComponentsProps, LogicalFilter, useExport } from '@refinedev/core';
import { Button, Form, FormInstance, Input, Rate, Select, Space } from 'antd';
import { useEffect, useRef, useState } from 'react';

import CandidatesTable from '../../components/candidatesTable';
import { Candidate } from '../../utilities/types';

const CandidateList: React.FC<IResourceComponentsProps> = () => {
  const [rating, setRating] = useState(0);

  const formRef = useRef<FormInstance>(null);

  const { tableProps, searchFormProps, setFilters, filters } = useTable<
    Candidate,
    HttpError,
    Pick<Candidate, 'firstName' | 'lastName' | 'email' | 'jobPosition' | 'rating'>
  >({
    syncWithLocation: true,
    sorters: {
      initial: [
        {
          field: 'createdAt',
          order: 'desc'
        }
      ]
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { firstName, lastName, email, jobPosition, rating } = params;

      filters.push({
        field: 'firstName',
        operator: 'contains',
        value: firstName
      });

      filters.push({
        field: 'lastName',
        operator: 'contains',
        value: lastName
      });

      filters.push({
        field: 'email',
        operator: 'contains',
        value: email
      });

      filters.push({
        field: 'jobPosition.title',
        operator: 'contains',
        value: jobPosition
      });

      filters.push({
        field: 'rating',
        operator: 'gte',
        value: rating
      });

      return filters;
    },
    meta: {
      populate: ['resume', 'letter', 'jobPosition']
    }
  });

  const { selectProps } = useSelect({
    resource: 'jobPositions',
    optionValue: 'title',
    sorters: [
      {
        field: 'id',
        order: 'asc'
      }
    ]
  });

  const typedFilters = filters as LogicalFilter[];

  const handleRatingChange = (value: number) => {
    setRating(value);
  };

  const handleReset = () => {
    formRef.current?.resetFields();
    setRating(0);
  };

  const { triggerExport, isLoading: exportLoading } = useExport<Candidate>({
    resource: 'candidates',
    meta: {
      populate: ['jobPosition']
    },
    mapData: (candidate) => {
      return {
        id: candidate.id,
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        email: candidate.email,
        jobPosition: candidate.jobPosition?.title ?? '',
        rating: candidate.rating,
        notes: candidate.notes,
        status: candidate.status
      };
    }
  });

  useEffect(() => {
    if (typedFilters.length > 0) {
      typedFilters.forEach((filter) => {
        switch (filter.field) {
          case 'jobPosition.title':
            formRef.current?.setFieldsValue({
              jobPosition: selectProps.options?.find((option) => option.value === filter.value)?.label
            });
            break;
          case 'rating':
            setRating(parseFloat(filter.value));
            formRef.current?.setFieldsValue({
              rating: rating
            });
            break;
          default:
            formRef.current?.setFieldsValue({
              [filter.field]: filter.value
            });
            break;
        }
      });
    }
  }, [selectProps, rating]);

  return (
    <List
      headerProps={{
        extra: (
          <Space>
            <ExportButton onClick={triggerExport} loading={exportLoading} />
          </Space>
        )
      }}
    >
      <Form layout="inline" {...searchFormProps} ref={formRef}>
        <Form.Item label="First Name" name="firstName">
          <Input placeholder="First Name" prefix={<SearchOutlined />} />
        </Form.Item>
        <Form.Item label="Last Name" name="lastName">
          <Input placeholder="Last Name" prefix={<SearchOutlined />} />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input placeholder="Email" prefix={<SearchOutlined />} />
        </Form.Item>
        <Form.Item label="Job Position" name="jobPosition">
          <Select style={{ minWidth: 168 }} placeholder="Select Job Position" {...selectProps} />
        </Form.Item>
        <Form.Item label="Rating" name="rating">
          <Rate allowHalf onChange={handleRatingChange} value={rating} />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            Filter
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            danger
            onClick={() => {
              setFilters([], 'replace');
              handleReset();
            }}
          >
            Reset
          </Button>
        </Form.Item>
      </Form>
      <CandidatesTable {...tableProps} style={{ marginTop: 20 }} />
    </List>
  );
};

export default CandidateList;
