import { BooleanField, Show, TextField, useTable } from '@refinedev/antd';
import { IResourceComponentsProps, useShow } from '@refinedev/core';
import { Col, Row, Select, Typography } from 'antd';
import React, { useState } from 'react';

import CandidatesTable from '../../components/candidatesTable';
import { Candidate } from '../../utilities/types';

const { Title } = Typography;

const JobPositionShow: React.FC<IResourceComponentsProps> = () => {
  const { showId, queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const [filter, setFilter] = useState();

  const record = data?.data;

  const { tableProps } = useTable<Candidate>({
    resource: 'candidates',
    filters: {
      permanent: [
        {
          field: 'jobPosition.id',
          operator: 'eq',
          value: showId
        },
        {
          field: 'status',
          operator: 'eq',
          value: filter
        }
      ]
    },
    sorters: {
      initial: [
        {
          field: 'createdAt',
          order: 'desc'
        }
      ]
    },
    meta: {
      populate: ['resume', 'letter', 'jobPosition']
    }
  });

  return (
    <Show isLoading={isLoading}>
      <Row gutter={30}>
        <Col>
          <Title level={5}>Job Position</Title>
          <TextField value={record?.title} />
        </Col>
        <Col>
          <Title level={5}>Location</Title>
          <TextField value={record?.location} />
        </Col>
        <Col>
          <Title level={5}>Hidden</Title>
          <BooleanField value={record?.hidden} />
        </Col>
        <Col>
          <Title level={5}>Show only</Title>
          <Select
            onChange={(opt) => {
              setFilter(opt?.value || null);
            }}
            allowClear
            labelInValue
            placeholder={'Select a state'}
            options={[{ value: 'to-contact' }, { value: 'interviewed' }, { value: 'rejected' }, { value: 'contacted' }]}
          />
        </Col>
        <Col span={24} style={{ marginTop: 20 }}>
          <Title level={5}>Candidates</Title>
          <CandidatesTable {...tableProps} style={{ marginTop: 20 }} hideRelations />
        </Col>
      </Row>
    </Show>
  );
};

export default JobPositionShow;
