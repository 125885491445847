import { useGetIdentity } from '@refinedev/core';
import { Layout as AntdLayout, Space, Typography } from 'antd';

const { Text } = Typography;

type IUser = {
  id: number;
  name: string;
};

const Header: React.FC = () => {
  const { data: user } = useGetIdentity<IUser>();

  return (
    <AntdLayout.Header
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0px 24px',
        height: '64px'
      }}
    >
      <Space>
        <Space style={{ marginLeft: '8px' }}>
          {user?.name && (
            <Text style={{ color: 'white' }} strong>
              {user.name}
            </Text>
          )}
        </Space>
      </Space>
    </AntdLayout.Header>
  );
};

export default Header;
