export type Nil = null | undefined;
export type Empty = never[] | Record<string, never> | '';
export type Maybe<T> = T | undefined;
export type Nullable<T> = T | null;
export type NilOrEmpty = Nil | Empty;

export enum CandidateStatus {
  REJECTED = 'rejected',
  INTERVIEWED = 'interviewed',
  CONTACTED = 'contacted',
  TO_CONTACT = 'to-contact'
}

export type Candidate = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  jobPosition?: JobPosition;
  links: { url: string }[];
  resume: StrapiFile;
  letter?: StrapiFile;
  notes: string;
  processed?: boolean;
  rating: number;
  interviewDate: string;
  status: CandidateStatus;
};

export type JobPosition = {
  id: number;
  title: string;
  slug: string;
  location: string;
  description: string;
  content: string;
  hidden: boolean;
};

export type StrapiFile = {
  id: number;
  url: string;
  ext: string;
  mime: string;
};
