import { DownloadOutlined } from '@ant-design/icons';
import { DateField, EditButton, EmailField, FilterDropdown, ShowButton, useSelect } from '@refinedev/antd';
import { Button, Rate, Select, Space, Table, TableProps } from 'antd';
import { Link } from 'react-router-dom';

import { Candidate, JobPosition } from '../utilities/types';

const CandidatesTable = ({ hideRelations, ...props }: TableProps<Candidate> & { hideRelations?: boolean }) => {
  // const { selectProps } = useSelect<JobPosition>({
  //   resource: 'jobpositions',
  //   optionValue: 'title',
  //   sorters: [
  //     {
  //       field: 'title',
  //       order: 'asc'
  //     }
  //   ]
  // });

  return (
    <Table {...props} rowKey="id">
      <Table.Column dataIndex="firstName" title="First Name" ellipsis={{ showTitle: true }} sorter={{ multiple: 1 }} />
      <Table.Column dataIndex="lastName" title="Last Name" ellipsis={{ showTitle: true }} sorter={{ multiple: 2 }} />
      <Table.Column
        dataIndex="email"
        title="Email"
        sorter={{ multiple: 3 }}
        render={(value) => <EmailField value={value} />}
      />
      {!hideRelations && (
        <Table.Column
          dataIndex={['jobPosition', 'title']}
          sorter={{
            multiple: 4
          }}
          title="Job Position"
          ellipsis={{ showTitle: true }}
          render={(_, record: Candidate) =>
            record.jobPosition && (
              <Link to={`/jobpositions/show/${record.jobPosition.id}`}>{record.jobPosition.title}</Link>
            )
          }
          // filterDropdown={(props) => (
          //   <FilterDropdown {...props}>
          //     <Select mode="multiple" style={{ minWidth: 200 }} placeholder="Select Job Position" {...selectProps} />
          //   </FilterDropdown>
          // )}
        />
      )}
      <Table.Column
        dataIndex="createdAt"
        title="Reception Date"
        sorter={{
          multiple: 5
        }}
        render={(value) => value && <DateField value={value} format="YYYY-MM-DD" />}
      />
      <Table.Column
        dataIndex="interviewDate"
        title="Interview Date"
        sorter={{
          multiple: 6
        }}
        render={(value) => value && <DateField value={value} format="YYYY-MM-DD" />}
      />
      <Table.Column
        dataIndex="rating"
        title="Rating"
        sorter={{
          multiple: 7
        }}
        render={(value) => <Rate disabled allowHalf value={value} />}
      />
      <Table.Column
        dataIndex="status"
        title="Status"
        sorter={{
          multiple: 8
        }}
      />
      <Table.Column
        title="Actions"
        dataIndex="actions"
        render={(_, record: Candidate) => (
          <Space>
            <EditButton hideText size="small" resource="candidates" recordItemId={record.id} />
            <ShowButton hideText size="small" resource="candidates" recordItemId={record.id} />
            {record.resume && (
              <Button
                size="small"
                href={`${record.resume.url}`}
                download={`${record.firstName}-${record.lastName}-resume${record.resume.ext}`}
                target="_blank"
              >
                <DownloadOutlined /> Resume
              </Button>
            )}
            {record.letter && (
              <Button
                size="small"
                href={`${record.letter.url}`}
                download={`${record.firstName}-${record.lastName}-resume${record.letter.ext}`}
                target="_blank"
              >
                <DownloadOutlined /> Motivational letter
              </Button>
            )}
          </Space>
        )}
      />
    </Table>
  );
};

export default CandidatesTable;
