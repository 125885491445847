import { SearchOutlined } from '@ant-design/icons';
import { BooleanField, DeleteButton, EditButton, List, ShowButton, useSelect, useTable } from '@refinedev/antd';
import { CrudFilters, HttpError, IResourceComponentsProps, LogicalFilter } from '@refinedev/core';
import { Button, Form, FormInstance, Input, Select, Space, Table, Tag } from 'antd';
import React, { useEffect, useRef } from 'react';

import { Candidate, JobPosition } from '../../utilities/types';

const JobPositionList: React.FC<IResourceComponentsProps> = () => {
  const formRef = useRef<FormInstance>(null);

  const { tableProps, searchFormProps, setFilters, filters } = useTable<
    JobPosition,
    HttpError,
    Pick<JobPosition, 'title' | 'location'>
  >({
    syncWithLocation: true,
    sorters: {
      initial: [
        {
          field: 'createdAt',
          order: 'desc'
        }
      ]
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { title, location } = params;

      filters.push({
        field: 'title',
        operator: 'contains',
        value: title
      });

      filters.push({
        field: 'location',
        operator: 'contains',
        value: location
      });

      return filters;
    },
    meta: {
      populate: ['candidates']
    }
  });

  const { selectProps } = useSelect<JobPosition>({
    resource: 'jobpositions',
    optionValue: 'title',
    sorters: [
      {
        field: 'title',
        order: 'asc'
      }
    ]
  });

  const typedFilters = filters as LogicalFilter[];

  useEffect(() => {
    if (typedFilters.length > 0) {
      typedFilters.forEach((filter) => {
        formRef.current?.setFieldsValue({
          [filter.field]: filter.value
        });
      });
    }
  }, []);

  const handleReset = () => {
    formRef.current?.resetFields();
  };

  return (
    <List>
      <Form layout="inline" {...searchFormProps} ref={formRef}>
        <Form.Item label="Job Position" name="title">
          <Select style={{ minWidth: 200 }} placeholder="Select Job Position" {...selectProps} />
        </Form.Item>
        <Form.Item label="Location" name="location">
          <Input placeholder="Location" prefix={<SearchOutlined />} />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            Filter
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            danger
            onClick={() => {
              setFilters([], 'replace');
              handleReset();
            }}
          >
            Reset
          </Button>
        </Form.Item>
      </Form>
      <Table {...tableProps} rowKey="id" style={{ marginTop: 20 }}>
        <Table.Column dataIndex="title" title="Job Position" ellipsis={{ showTitle: true }} sorter={{ multiple: 1 }} />
        <Table.Column dataIndex="location" title="Location" sorter={{ multiple: 2 }} />
        <Table.Column
          dataIndex="candidates"
          title="Candidates"
          render={(value?: Candidate[]) => <Tag>{value?.length ?? 0}</Tag>}
        />
        <Table.Column
          dataIndex="hidden"
          title="Hide Position"
          sorter={{ multiple: 3 }}
          render={(value) => <BooleanField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: JobPosition) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

export default JobPositionList;
