import 'react-quill/dist/quill.snow.css';

import { Create, useForm } from '@refinedev/antd';
import { IResourceComponentsProps } from '@refinedev/core';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import React from 'react';
import ReactQuill from 'react-quill';

import { slugify } from '../../utilities/functions';
import { JobPosition } from '../../utilities/types';

const JobPositionEdit: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, saveButtonProps } = useForm<JobPosition>();

  const onChangeSetSlug = () =>
    form.setFieldValue(
      'slug',
      `${slugify(form.getFieldValue('title'))}-${slugify(form.getFieldValue('location' ?? ''))}`
    );

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" requiredMark="optional" scrollToFirstError>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Job Position"
              name="title"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input onChange={onChangeSetSlug} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Location"
              name="location"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input onChange={onChangeSetSlug} />
            </Form.Item>
          </Col>
          <Form.Item
            label="Slug"
            name="slug"
            rules={[
              {
                required: true
              }
            ]}
            hidden
          >
            <Input disabled />
          </Form.Item>
          <Col>
            <Form.Item label="Hide Position" valuePropName="checked" name="hidden">
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Job Brief Description"
              name="description"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input.TextArea autoSize={{ minRows: 3 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Job Requirements"
              name="content"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <ReactQuill style={{ height: 300, marginBottom: 30 }} preserveWhitespace />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};

export default JobPositionEdit;
