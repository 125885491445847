import { DownloadOutlined } from '@ant-design/icons';
import { Edit, EmailField, TextField, useForm } from '@refinedev/antd';
import { IResourceComponentsProps, useNavigation, useShow } from '@refinedev/core';
import { Button, Col, DatePicker, Form, Input, Rate, Row, Select, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { isNotNil, isNotNilOrEmpty } from 'src/utilities/functions';

import { STRAPI_MEDIA_URL } from '../../constants';
import { Candidate, CandidateStatus } from '../../utilities/types';

const { Title } = Typography;

const CandidateEdit: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<Candidate>({
    meta: {
      populate: ['resume', 'links', 'letter', 'jobPosition']
    }
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const { goBack } = useNavigation();

  const handleSave = () => {
    if (saveButtonProps?.onClick) {
      saveButtonProps.onClick();
    }
    goBack();
  };

  const { formProps, saveButtonProps } = useForm<Candidate>({ redirect: false, warnWhenUnsavedChanges: false });

  return (
    <Edit
      mutationMode="optimistic"
      saveButtonProps={{
        ...saveButtonProps,
        onClick: handleSave
      }}
    >
      {!isLoading && (
        <>
          <Row gutter={30}>
            <Col span={4}>
              <Title level={5}>First Name</Title>
              <TextField value={record?.firstName} />
            </Col>
            <Col span={4}>
              <Title level={5}>Last Name</Title>
              <TextField value={record?.lastName} />
            </Col>
          </Row>
          <Row gutter={30} style={{ marginTop: 20 }}>
            <Col span={4}>
              <Title level={5}>Email</Title>
              <EmailField value={record?.email} />
            </Col>
          </Row>
          {isNotNil(record) && isNotNilOrEmpty(record.links) && (
            <Row gutter={30} style={{ marginTop: 20 }}>
              <Col span={24}>
                <Title level={5}>Links</Title>
                <Space direction="vertical">
                  {record.links.map((link) => (
                    <Link to={link.url} target="_blank">
                      {link.url}
                    </Link>
                  ))}
                </Space>
              </Col>
            </Row>
          )}
          <Form {...formProps} layout="vertical" requiredMark="optional" scrollToFirstError>
            <Row gutter={16} style={{ marginTop: 20 }}>
              <Col span={4}>
                <Form.Item label="Status" name="status">
                  <Select
                    defaultValue={CandidateStatus.TO_CONTACT}
                    options={Object.values(CandidateStatus).map((value) => ({ value }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 20 }}>
              <Col span={8}>
                <Form.Item label="Notes" name="notes">
                  <Input.TextArea autoSize={{ minRows: 5 }} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Interview Date"
                  name="interviewDate"
                  getValueProps={(value) => ({
                    value: value ? dayjs(value) : ''
                  })}
                >
                  <DatePicker disabledDate={(current) => current < dayjs().startOf('day')} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Rating"
                  name="rating"
                  rules={[
                    {
                      required: true
                    }
                  ]}
                >
                  <Rate allowClear allowHalf />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {(record?.resume || record?.letter) && (
            <>
              <Title level={5} style={{ marginTop: 20 }}>
                Actions
              </Title>
              <Row gutter={30}>
                {record?.resume && (
                  <Col>
                    <Button target="_blank" href={`${STRAPI_MEDIA_URL}${record.resume.url}`}>
                      <DownloadOutlined /> Download Resume
                    </Button>
                  </Col>
                )}
                {record?.letter && (
                  <Col>
                    <Button target="_blank" href={`${STRAPI_MEDIA_URL}${record.letter.url}`}>
                      <DownloadOutlined /> Download Motivational letter
                    </Button>
                  </Col>
                )}
              </Row>
            </>
          )}
        </>
      )}
    </Edit>
  );
};

export default CandidateEdit;
